.login-section, .otp-section, .get-otp, .register, .add-patient, .edit-page, .support {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.login-section, .otp-section {
    margin: 0 auto;
    height: 100vh;
}
/* .login-section {
    margin-top: 80px;
} */

/* .title {
    font-size: 45px;
    font-weight: 800;
    margin: 50px auto;
    color: #3ab5fb;
} */

.login-section .logo {
    margin: 160px auto 0;
}
.otp-section .logo {
    margin: 100px auto 0;
}

.logo img {
    width:75px;
}

.input-section, .register-form, .addpatient-form, #edit-form, .support-form {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 98%;
}

#input-field {
    padding: 20px 25px;
    margin: 40px auto 20px;
    color: #535050;
    border-radius: 10px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1.5px;
    width: 40%;
}
.inputField {
    border: 2px solid #ededed;
}
::placeholder {
    color: #a6a6a6;
    opacity: 1; 
}
  
  :-ms-input-placeholder { 
    color: #a6a6a6;
}
  
  ::-ms-input-placeholder {
    color: #a6a6a6;
}

.getotp, .verify, .register {
    width: 99%;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
}

.getotp-btn, .verify-btn, .register-btn, .addpatient-btn, .save-btn {
    cursor: pointer;
    font-size: 2rem;
    background-color: #3ab5fb;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 20px auto 10px;
    padding: 20px 24px;
    border-radius: 10px;
    border: none;
    width: 44%;
}
.save-btn, .addpatient-btn {
    margin: 30px auto 50px;
}
.verify-btn {
   margin: 30px auto;
   width: 35%;
}

.register-btn {
    margin: 30px auto;
}

.get-otp .phone {
    font-size: 1.8rem;
}

.go-back {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 35%;
    margin: 30px auto;
}
.otp-number {
    margin: 40px 10px 20px;
}
.input-number {
    height: 70px;
    width: 70px;
    padding: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    border: 3px solid #3ab5fb;
    margin: 0 1rem;
    border-radius: 5px;
    text-align: center;
}

.goback-link, .timer {
    font-size: 28px;
    font-weight: 500;
    color: #3ab5fb;
    text-decoration: underline;
    margin: 0;
    padding: 0;
}

.timer {
    color: #a6a6a6;
    text-decoration: none;
}
.closeBtnContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 30px;
}
.close-btn {
    padding: 20px;
}
.invalid {
    border: 2px solid #ff7a7a;
    background-image: url(./../images/icon-error.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
}    
.field-error {
    display: none;
    font-size: 0.85em;
    align-self: flex-end;
    position: relative;
    margin: 0 50px 10px 0;
    color: #ff7a7a;
}

/* ------- Media queries ------- */

@media(max-width:1100px) {

    #input-field {
        width: 45%;
    }
    .go-back {
        width: 50%;
    }
    .getotp-btn, .verify-btn, .register-btn, .addpatient-btn, .save-btn {
        width: 50%;
    }
    .save-btn, .register-btn, .addpatient-btn {
        width: 55%;
        margin: 30px auto 50px;
    }
}

@media(max-width:768px) {

    .register .title, .edit-page .title, .support .title {
        font-size: 32px;
    }
    #input-field {
        width: 45%;
        padding: 15px 20px;
        font-size: 1rem;
    }
    .get-otp .phone {
        font-size: 1.2rem;
    }
    .input-number {
        height: 60px;
        width: 60px;
        padding: 5px;
    }   
   .title {
        font-size: 45px;
        font-weight: 800;
        margin: 50px auto;
        color: #3ab5fb;
    }
    .getotp-btn, .verify-btn, .register-btn, .addpatient-btn, .save-btn {
        font-size: 1rem;
        padding: 15px 20px;
        width: 52%;
    }
    .verify-btn {
        width: 42%;
    }
    .register-btn {
        width: 58%;
    }
    .save-btn,  .addpatient-btn {
        width: 55%;
    }
    
    .go-back {
        width: 42%;
    }
    .goback-link, .timer {
        font-size: 20px;
    }
    .register-form, .addpatient-form, .support-form {
        width: 95%;
    }
    .register .title, .edit-page .title, .support .title {
        font-size: 24px;
    }
}

@media(max-width:425px) {
    #input-field { 
        width: 75%;
    }
    .getotp-btn, .verify-btn, .register-btn, .save-btn {
        margin: 0 auto;
        width: 88%;
    }
    .save-btn {
        margin: 30px auto 40px;
    }
    .register-btn, .addpatient-btn {
        margin: 30px auto;
        width: 93%;
    }
    .verify-btn {
        margin: 20px auto;
        width: 70%;
    }
    .go-back {
        width: 68%;
    }
    .input-number {
        padding: 2px;
    }
}

@media(max-width:320px) {
    
    #input-field {
        width: 80%;
    }
    .getotp-btn, .verify-btn, .register-btn, .save-btn {
        width: 95%;
    }
    .save-btn, .verify-btn {
        width: 92%;
    }
    .go-back {
        width: 83%;
    }
}