@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.introduction {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  background-color: white;
  height: 100vh;
}

.brand {
  font-size: 4rem;
  font-weight: 400;
  color: #3ab5fb;
  margin: 0 auto;
  padding: 0;
  letter-spacing: 2px;
}

.description {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 300;
  color: #000;
  margin: 0 auto;
  padding: 0;
  letter-spacing: 1.5px;
  line-height: 1.5;
  max-width: 820px;
}
.login {
  width: 99%;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
}

.login-btn {
  cursor: pointer;
  font-size: 2rem;
  background-color: #3ab5fb;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 8rem auto 1rem;
  padding: 20px 24px;
  border-radius: 10px;
  border: none;
  width: 50%;
}

#response {
  position: fixed;
  bottom: 50px;
  text-align: center;
  max-width: 300px;
  transform: translate(calc(50vw - 50%));
  z-index: 10;
}

.success-msg {
  background: #C5EBD4;
  color: #184B21;
}

.error-msg {
  background: #FFCCCC;
  color: #740D0A;
}

.warning-msg {
  background: #fffecc;
  color: #72740a;
}

.msg {
  margin: 20px auto;
  padding: 25px;
  border-left: 2px solid;
}

#banner {
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #3ab5fb;
  -webkit-box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.54);
  -moz-box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.54);
  box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.54);
  max-width: 500px;
  display: none;
  justify-content: flex-start;
}

#banner img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
}

#banner .logo-wrap {
  margin:auto;
}

#banner a {
  margin: auto;
  background-color: #fff;
  color: #3ab5fb;
  padding: 5px 15px;
  font-size: 1.2rem;
  border-radius: 30px;
}
/*------- Media queries ------*/

@media(max-width:1100px) {

  .login-btn {
    width: 60%;
  }
}

@media(max-width:768px) {

  .brand {
    margin: 80px auto 0;
    font-size: 2.5rem;
  }  
  .description {
    font-size: 1.5rem;
    max-width: 500px;
    line-height: 1.5;
  }
  .login-btn {
    font-size: 1rem;
    padding: 15px 20px;
    width: 50%;
  }
}

@media(max-width:425px) {
  
  .brand {
    font-size: 3rem;
    margin: 40px 0 10px;
    letter-spacing: 1.5px;
  }
  
  .description {
    font-size: 1.2rem;
    margin: 0 0 4rem;
    max-width: 350px;
  }
  
  .login-btn {
    font-size: 1rem;
    padding: 15px 20px;
    margin: 6rem 0 1rem;
    width: 80%;
  }

}

@media(max-width:320px) {

  .brand {
    margin: 80px auto 0;
  }
  .description {
    font-size: 1rem;
    margin: 0 0 80px;
    max-width: 300px;
  }
  .login-btn {
    width: 90%;
  }
}