.Body {
    background-color: #3ab5fb;
    width: 100vw;
}

.terms-section, .card-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.logo-blue {
    margin: 50px auto 30px;
}
.card-container
{
    margin: 0 0 100px 0;
}
.center {
    text-align: center;
}

.Body .title, .card-container .title {
    font-size: 40px;
    color: #ffffff;
    margin: 0 auto 30px;
}

.card-container .title {
    color:#3ab5fb;
    margin: 50px auto;
}

.terms {
    width: 60%;
    margin: 0 auto 30px;
}

.terms-conditions {
    width: 99%;
}

.conditions, .no-data .Nodata {
    font-size: 30px;
    color: #ffffff;
    margin: 20px auto;
    line-height: 1.5;
}

.agreement {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.agreement {
    margin: 10px 20px;
}
/* .hidden-title
{
    font-size: 19px;
    padding: 10px;
    //opacity: 0;
    transition: opacity 0.2s ease 0s;
    color: #3ab5fb;
}
.show
{
    opacity: 1;
}

.navBar{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.hamburger-menu
{
    padding:10px;
    margin: 10px;
    /* background-color: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 100%);
} */
.navigation {
    position: fixed;
    z-index: 2;
    background-color: white;
    width: 100%;
    top: -1px;
    box-shadow: 0 -6px 16px 0px #8a8a8a;
}
.navigation-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
.nav-title span {
    font-size: 24px;
    font-weight: 400;
    color: #3ab5fb;
    /* margin: 0 0 0 20px; */
}


.hamburger-menu
{
    /*position: fixed;
    padding: 10px;
    margin: 40px 20px 30px;*/
    margin: 15px 20px;
}
.menubar
{
    outline: solid;
    height: 100%;  
    width: 100%;
    max-width: 500px;  
    position: fixed; 
    z-index: 1; 
    top: 0; 
    left: 0;
    outline: solid;
    background-color:white; 
    transition: .5s ease; 
    overflow-x: hidden; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.menubar nav {
    width: 100%;
}

.menubar nav p.app-version {
    text-align: center;
}

#myCheck, .input-checkbox {
    height: 40px;
    width: 40px;
    border: 3px solid #ffffff;
    border-radius: 10px;
    margin: 20px 10px;
    background-color: #3ab5fb;
}

.agree {
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    margin: 0 0 0 10px;
}

.terms-continue-btn-wrap {
    width: 100%;
    margin: 10px;
    text-align: center;
    height: 80px;
}

.continue {
    width: 99%;
    margin: 20px auto;
    text-align: center;
    text-decoration: none;
}

.continue-btn, .add-btn {
    cursor: pointer;
    font-size: 2rem;
    background-color: #ffffff;
    color: #3ab5fb;
    padding: 20px 24px;
    border-radius: 10px;
    border: none;
}

.add-btn {
    background-color: #3ab5fb;
    color: #ffffff;
}

.details-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    height: 280px;
    width: 450px;
    background-color: #cdecfd;
    color: black;
    border-radius: 10px;
    margin: 10px auto;
}

.name, .age, .date, .program {
    font-size: 24px;
    font-weight: 400;
    color: black;
    margin: 10px;
}

@media(max-width:1100px) {

    .terms {
        width: 70%;
    }
    .continue-btn {
        width: 60%;
    }
}

@media(max-width:768px) {

    .terms {
        width: 80%;
    }
    .continue-btn {
        width: 55%;
        padding: 15px 20px;
        font-size: 1.3rem;
    }
    .terms-section .title {
        font-size: 28px;
    }
    .conditions, .no-data .Nodata  {
        font-size: 24px;
    }
    #myCheck {
        height: 35px;
        width: 35px;
    }
    
    .agree {
        font-size: 24px;
    }
}

@media(max-width:425px) {

    .Body .title, .card-container .title {
        font-size: 30px;
    }
    .terms {
        width: 98%;
    }
    .terms-conditions {
        width: 90%;
    }
    .terms-section .title {
        font-size: 24px;
    }
    .conditions, .no-data .Nodata  {
        font-size: 18px;
        padding: 0 20px;
    }
    .agree {
        font-size: 18px;
    }
    .continue-btn, .add-btn {
        font-size: 1rem;
        padding: 15px 20px;
        width: 80%;
    }
    .details-card {
        height: 260px;
        width: 330px;
    }
    .name, .age, .date, .program {
        font-size: 20px;
        margin: 10px 20px;
    }
}

@media(max-width:320px) {

    .details-card {
        height: 240px;
        width: 300px;
    }
    .name, .age, .date, .program {
        font-size: 18px;
        margin: 10px 20px;
    }
    .conditions {
        font-size: 18px;
    }
}