
.home, .my-account {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.closeBtnContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 30px;
}
.closeBtnContainer .close-btn {
    padding: 20px;
}
/* .homecomponent
{
    height: 100vh;
}

.navigation {
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    box-shadow: -6px -4px 9px 1px #8a8a8a;
    height: 75px;
     z-index: 1;
}

.navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-title span {
    color: #3ab5fb;
    font-size: 2rem;
    font-weight: 500;
}

.menubar
{
    outline: solid;
    height: 100%;  
    width: 100%;
    max-width: 500px;  
    position: fixed; 
    z-index: 1; 
    top: 0; 
    left: 0;
    outline: solid;
    background-color:white; 
    transition: .5s ease; 
    overflow-x: hidden; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.menubar nav {
    width: 100%;
}

.home .title {
    font-size: 40px;
    /* margin: 10px auto 30px; 
    color: #3ab5fb;
}*/
.no-data {
    margin: 5rem auto 30px;
}
.edit-img {
    cursor:pointer;
}

.nav-links {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    list-style-type: none;
    padding: 0;
    width: fit-content;
    height:80vh;
}

.nav-items img {
    margin: 0 10px 0 0;
}
.nav-items a {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: #000000;
    font-size: 28px;
    margin: 30px 0 0;
}
.nav-items:hover {
    color: #3ab5fb;
}

.my-account {
    font-size: 22px;
    margin: 0 auto 50px;
}
/* .my-account .close-btn {
    cursor: pointer;
    position: relative;
    font-size: 28px;
    top: 0;
    color: #3ab5fb;
} */
.topic {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    /* margin: 80px 20px 20px; */
    margin: 80px 0 0 0;
}
.topic .title {
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    color: #3ab5fb;
    position: sticky;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 100%);
}
#doctor-details {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0 20px;
}
.d-name, .d-email, .clinic, .speciality, .mobile-number {
    font-size: 35px;
    color: #3ab5fb;
    font-weight: 400;
    margin: 20px 10px;
    padding: 0;
}
#doctor-name, #doctor-email, #doctor-speciality, #clinicName, #alternate-number {
    margin: 0 0 0 25px;
    padding: 10px;
    font-size: 32px;
    font-weight: 400;
    color: #000000;
} 
/* #doctor-name, #doctor-email, #doctor-speciality, #clinic-name, #doctor-phn {
    padding: 20px 25px;
    border-radius: 10px;
    border: 5px solid #ededed;
    font-size: 26px;
    color: #a6a6a6;
    width: 90%;
    margin: 10px 0 20px;
} */
.my-wallet {
    margin: 20px auto 0;
}
.my-wallet .title {
    text-align: center;
    margin: 10px auto;
    color: #3ab5fb;
    font-weight: 500;
}
.card-container, .totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card, .my-wallet {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card {
    width: 350px;
    height: 200px;
    margin: 20px auto;
    border-radius: 10px;
}
.total-earning, .paidout, .patients-referred, .patients-onboarded {
    font-size: 24px;
}
.totalEarning, .paidOut, .patientsReferred, .patientsOnboarded {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.totalearning-card {
    background-color: #cdfdd5;
}

.paidout-card {
    background-color:#d0cdfd;
}

.patientsreferred-card {
    background-color: #cdecfd;
}

.patientsonboarded-card {
    background-color: #fdcdcd;
}

.cashout{
    /* width: 100%; */                                 
}

.cashout-btn,  .add-btn {
    font-size: 2rem;
    background-color: #3ab5fb;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 20px 25px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
.add-btn
{
    bottom: 10px;
}
.btn-holder{
    position: fixed;
    width: 99%;
    padding: 20px;
    /* background-color: white; */
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 100%);
    bottom: -1px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

}
.save-btn {
    margin: 50px auto;
    width: 35%;
}

@media(max-width:1100px) {
    
    .save-btn, .home .add-btn {
        width: 100%;
    }
}

@media(max-width:768px) {

    
    .my-account {
        align-items: flex-start;
    }
    .topic .title, .my-wallet .title, .home .title, .home .no-data {
        font-size: 30px;
        margin: 20px auto;
    }
    
    .d-name, .d-email, .clinic, .speciality, .mobile-number {
        font-size: 22px;
    }
    #doctor-name, #doctor-email, #doctor-speciality, #clinicName, #alternate-number {
        font-size: 24px;
    } 
    .cashout-btn, .home .add-btn {
        font-size: 1rem;
        padding: 15px 20px;
        width: 100%;
    }
    .total-earning, .paidout, .patients-referred, .patients-onboarded {
        font-size: 20px;
    }
}

@media(max-width:425px) {

    .home .title, .home .no-data {
        font-size: 28px;
    }
    .cashout-btn, .home .add-btn {
        width: 100%;
    }
    .my-wallet .title, .topic .title {
        font-size: 26px;
    }
    .totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card {
        width: 300px;
    }
    .total-earning, .paidout, .patients-referred, .patients-onboarded {
        font-size: 18px;
    }
    .nav-items a {
        font-size: 24px;
    }
}

@media(max-width:320px) {

    .close-btn {
        font-size: 35px;
    }
    .home .title,.home .no-data {
        font-size: 24px;
    }
    .my-wallet .title{
        font-size: 26px;
    }
    .totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card {
        width: 300px;
    }
    .cashout-btn, .home .add-btn {
        width: 100%;
    }
}