.popup {
    display: flex;  
    justify-content: center;
    align-items: center;
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}
.popup-content {
    background-color: #3ab5fb;
    margin: 0 auto 0;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 90%; 
}
.popup-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.popup-message {
    color: white;
    font-size: 18px;
    font-weight: 450;
    margin: 0 0 10px;
    padding: 0 5px;
}
.popup-message .share {
    position: relative;
    top: 18px;
}
.logo {
    margin: 0;
    padding: 0 5px;
}
.popup-close-icon {
    border: none;
    
}
    
@media(max-width:450px) {

    .popup-container {
        display: block;
    }
}

