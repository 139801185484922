@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
.introduction {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Poppins', sans-serif;
  background-color: white;
  height: 100vh;
}

.brand {
  font-size: 4rem;
  font-weight: 400;
  color: #3ab5fb;
  margin: 0 auto;
  padding: 0;
  letter-spacing: 2px;
}

.description {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 300;
  color: #000;
  margin: 0 auto;
  padding: 0;
  letter-spacing: 1.5px;
  line-height: 1.5;
  max-width: 820px;
}
.login {
  width: 99%;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
}

.login-btn {
  cursor: pointer;
  font-size: 2rem;
  background-color: #3ab5fb;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 8rem auto 1rem;
  padding: 20px 24px;
  border-radius: 10px;
  border: none;
  width: 50%;
}

#response {
  position: fixed;
  bottom: 50px;
  text-align: center;
  max-width: 300px;
  -webkit-transform: translate(calc(50vw - 50%));
          transform: translate(calc(50vw - 50%));
  z-index: 10;
}

.success-msg {
  background: #C5EBD4;
  color: #184B21;
}

.error-msg {
  background: #FFCCCC;
  color: #740D0A;
}

.warning-msg {
  background: #fffecc;
  color: #72740a;
}

.msg {
  margin: 20px auto;
  padding: 25px;
  border-left: 2px solid;
}

#banner {
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #3ab5fb;
  box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.54);
  max-width: 500px;
  display: none;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

#banner img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
}

#banner .logo-wrap {
  margin:auto;
}

#banner a {
  margin: auto;
  background-color: #fff;
  color: #3ab5fb;
  padding: 5px 15px;
  font-size: 1.2rem;
  border-radius: 30px;
}
/*------- Media queries ------*/

@media(max-width:1100px) {

  .login-btn {
    width: 60%;
  }
}

@media(max-width:768px) {

  .brand {
    margin: 80px auto 0;
    font-size: 2.5rem;
  }  
  .description {
    font-size: 1.5rem;
    max-width: 500px;
    line-height: 1.5;
  }
  .login-btn {
    font-size: 1rem;
    padding: 15px 20px;
    width: 50%;
  }
}

@media(max-width:425px) {
  
  .brand {
    font-size: 3rem;
    margin: 40px 0 10px;
    letter-spacing: 1.5px;
  }
  
  .description {
    font-size: 1.2rem;
    margin: 0 0 4rem;
    max-width: 350px;
  }
  
  .login-btn {
    font-size: 1rem;
    padding: 15px 20px;
    margin: 6rem 0 1rem;
    width: 80%;
  }

}

@media(max-width:320px) {

  .brand {
    margin: 80px auto 0;
  }
  .description {
    font-size: 1rem;
    margin: 0 0 80px;
    max-width: 300px;
  }
  .login-btn {
    width: 90%;
  }
}

.home, .my-account {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.closeBtnContainer{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: relative;
    top: 30px;
}
.closeBtnContainer .close-btn {
    padding: 20px;
}
/* .homecomponent
{
    height: 100vh;
}

.navigation {
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    box-shadow: -6px -4px 9px 1px #8a8a8a;
    height: 75px;
     z-index: 1;
}

.navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-title span {
    color: #3ab5fb;
    font-size: 2rem;
    font-weight: 500;
}

.menubar
{
    outline: solid;
    height: 100%;  
    width: 100%;
    max-width: 500px;  
    position: fixed; 
    z-index: 1; 
    top: 0; 
    left: 0;
    outline: solid;
    background-color:white; 
    transition: .5s ease; 
    overflow-x: hidden; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
}

.menubar nav {
    width: 100%;
}

.home .title {
    font-size: 40px;
    /* margin: 10px auto 30px; 
    color: #3ab5fb;
}*/
.no-data {
    margin: 5rem auto 30px;
}
.edit-img {
    cursor:pointer;
}

.nav-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin: 0 auto;
    list-style-type: none;
    padding: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height:80vh;
}

.nav-items img {
    margin: 0 10px 0 0;
}
.nav-items a {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    color: #000000;
    font-size: 28px;
    margin: 30px 0 0;
}
.nav-items:hover {
    color: #3ab5fb;
}

.my-account {
    font-size: 22px;
    margin: 0 auto 50px;
}
/* .my-account .close-btn {
    cursor: pointer;
    position: relative;
    font-size: 28px;
    top: 0;
    color: #3ab5fb;
} */
.topic {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    /* margin: 80px 20px 20px; */
    margin: 80px 0 0 0;
}
.topic .title {
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    color: #3ab5fb;
    position: -webkit-sticky;
    position: sticky;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 100%);
}
#doctor-details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 20px;
}
.d-name, .d-email, .clinic, .speciality, .mobile-number {
    font-size: 35px;
    color: #3ab5fb;
    font-weight: 400;
    margin: 20px 10px;
    padding: 0;
}
#doctor-name, #doctor-email, #doctor-speciality, #clinicName, #alternate-number {
    margin: 0 0 0 25px;
    padding: 10px;
    font-size: 32px;
    font-weight: 400;
    color: #000000;
} 
/* #doctor-name, #doctor-email, #doctor-speciality, #clinic-name, #doctor-phn {
    padding: 20px 25px;
    border-radius: 10px;
    border: 5px solid #ededed;
    font-size: 26px;
    color: #a6a6a6;
    width: 90%;
    margin: 10px 0 20px;
} */
.my-wallet {
    margin: 20px auto 0;
}
.my-wallet .title {
    text-align: center;
    margin: 10px auto;
    color: #3ab5fb;
    font-weight: 500;
}
.card-container, .totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card, .my-wallet {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card {
    width: 350px;
    height: 200px;
    margin: 20px auto;
    border-radius: 10px;
}
.total-earning, .paidout, .patients-referred, .patients-onboarded {
    font-size: 24px;
}
.totalEarning, .paidOut, .patientsReferred, .patientsOnboarded {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.totalearning-card {
    background-color: #cdfdd5;
}

.paidout-card {
    background-color:#d0cdfd;
}

.patientsreferred-card {
    background-color: #cdecfd;
}

.patientsonboarded-card {
    background-color: #fdcdcd;
}

.cashout{
    /* width: 100%; */                                 
}

.cashout-btn,  .add-btn {
    font-size: 2rem;
    background-color: #3ab5fb;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 20px 25px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
.add-btn
{
    bottom: 10px;
}
.btn-holder{
    position: fixed;
    width: 99%;
    padding: 20px;
    /* background-color: white; */
    background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 100%);
    bottom: -1px;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;

}
.save-btn {
    margin: 50px auto;
    width: 35%;
}

@media(max-width:1100px) {
    
    .save-btn, .home .add-btn {
        width: 100%;
    }
}

@media(max-width:768px) {

    
    .my-account {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    .topic .title, .my-wallet .title, .home .title, .home .no-data {
        font-size: 30px;
        margin: 20px auto;
    }
    
    .d-name, .d-email, .clinic, .speciality, .mobile-number {
        font-size: 22px;
    }
    #doctor-name, #doctor-email, #doctor-speciality, #clinicName, #alternate-number {
        font-size: 24px;
    } 
    .cashout-btn, .home .add-btn {
        font-size: 1rem;
        padding: 15px 20px;
        width: 100%;
    }
    .total-earning, .paidout, .patients-referred, .patients-onboarded {
        font-size: 20px;
    }
}

@media(max-width:425px) {

    .home .title, .home .no-data {
        font-size: 28px;
    }
    .cashout-btn, .home .add-btn {
        width: 100%;
    }
    .my-wallet .title, .topic .title {
        font-size: 26px;
    }
    .totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card {
        width: 300px;
    }
    .total-earning, .paidout, .patients-referred, .patients-onboarded {
        font-size: 18px;
    }
    .nav-items a {
        font-size: 24px;
    }
}

@media(max-width:320px) {

    .close-btn {
        font-size: 35px;
    }
    .home .title,.home .no-data {
        font-size: 24px;
    }
    .my-wallet .title{
        font-size: 26px;
    }
    .totalearning-card, .paidout-card, .patientsreferred-card, .patientsonboarded-card {
        width: 300px;
    }
    .cashout-btn, .home .add-btn {
        width: 100%;
    }
}
.Body {
    background-color: #3ab5fb;
    width: 100vw;
}

.terms-section, .card-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 auto;
}
.logo-blue {
    margin: 50px auto 30px;
}
.card-container
{
    margin: 0 0 100px 0;
}
.center {
    text-align: center;
}

.Body .title, .card-container .title {
    font-size: 40px;
    color: #ffffff;
    margin: 0 auto 30px;
}

.card-container .title {
    color:#3ab5fb;
    margin: 50px auto;
}

.terms {
    width: 60%;
    margin: 0 auto 30px;
}

.terms-conditions {
    width: 99%;
}

.conditions, .no-data .Nodata {
    font-size: 30px;
    color: #ffffff;
    margin: 20px auto;
    line-height: 1.5;
}

.agreement {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.agreement {
    margin: 10px 20px;
}
/* .hidden-title
{
    font-size: 19px;
    padding: 10px;
    //opacity: 0;
    transition: opacity 0.2s ease 0s;
    color: #3ab5fb;
}
.show
{
    opacity: 1;
}

.navBar{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.hamburger-menu
{
    padding:10px;
    margin: 10px;
    /* background-color: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 14%, rgba(255,255,255,1) 100%);
} */
.navigation {
    position: fixed;
    z-index: 2;
    background-color: white;
    width: 100%;
    top: -1px;
    box-shadow: 0 -6px 16px 0px #8a8a8a;
}
.navigation-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.nav-title span {
    font-size: 24px;
    font-weight: 400;
    color: #3ab5fb;
    /* margin: 0 0 0 20px; */
}


.hamburger-menu
{
    /*position: fixed;
    padding: 10px;
    margin: 40px 20px 30px;*/
    margin: 15px 20px;
}
.menubar
{
    outline: solid;
    height: 100%;  
    width: 100%;
    max-width: 500px;  
    position: fixed; 
    z-index: 1; 
    top: 0; 
    left: 0;
    outline: solid;
    background-color:white; 
    transition: .5s ease; 
    overflow-x: hidden; 
    display: -webkit-flex; 
    display: flex; 
    -webkit-flex-direction: column; 
            flex-direction: column; 
    -webkit-align-items: center; 
            align-items: center;
}

.menubar nav {
    width: 100%;
}

.menubar nav p.app-version {
    text-align: center;
}

#myCheck, .input-checkbox {
    height: 40px;
    width: 40px;
    border: 3px solid #ffffff;
    border-radius: 10px;
    margin: 20px 10px;
    background-color: #3ab5fb;
}

.agree {
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    margin: 0 0 0 10px;
}

.terms-continue-btn-wrap {
    width: 100%;
    margin: 10px;
    text-align: center;
    height: 80px;
}

.continue {
    width: 99%;
    margin: 20px auto;
    text-align: center;
    text-decoration: none;
}

.continue-btn, .add-btn {
    cursor: pointer;
    font-size: 2rem;
    background-color: #ffffff;
    color: #3ab5fb;
    padding: 20px 24px;
    border-radius: 10px;
    border: none;
}

.add-btn {
    background-color: #3ab5fb;
    color: #ffffff;
}

.details-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    height: 280px;
    width: 450px;
    background-color: #cdecfd;
    color: black;
    border-radius: 10px;
    margin: 10px auto;
}

.name, .age, .date, .program {
    font-size: 24px;
    font-weight: 400;
    color: black;
    margin: 10px;
}

@media(max-width:1100px) {

    .terms {
        width: 70%;
    }
    .continue-btn {
        width: 60%;
    }
}

@media(max-width:768px) {

    .terms {
        width: 80%;
    }
    .continue-btn {
        width: 55%;
        padding: 15px 20px;
        font-size: 1.3rem;
    }
    .terms-section .title {
        font-size: 28px;
    }
    .conditions, .no-data .Nodata  {
        font-size: 24px;
    }
    #myCheck {
        height: 35px;
        width: 35px;
    }
    
    .agree {
        font-size: 24px;
    }
}

@media(max-width:425px) {

    .Body .title, .card-container .title {
        font-size: 30px;
    }
    .terms {
        width: 98%;
    }
    .terms-conditions {
        width: 90%;
    }
    .terms-section .title {
        font-size: 24px;
    }
    .conditions, .no-data .Nodata  {
        font-size: 18px;
        padding: 0 20px;
    }
    .agree {
        font-size: 18px;
    }
    .continue-btn, .add-btn {
        font-size: 1rem;
        padding: 15px 20px;
        width: 80%;
    }
    .details-card {
        height: 260px;
        width: 330px;
    }
    .name, .age, .date, .program {
        font-size: 20px;
        margin: 10px 20px;
    }
}

@media(max-width:320px) {

    .details-card {
        height: 240px;
        width: 300px;
    }
    .name, .age, .date, .program {
        font-size: 18px;
        margin: 10px 20px;
    }
    .conditions {
        font-size: 18px;
    }
}
.login-section, .otp-section, .get-otp, .register, .add-patient, .edit-page, .support {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: center;
            align-items: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.login-section, .otp-section {
    margin: 0 auto;
    height: 100vh;
}
/* .login-section {
    margin-top: 80px;
} */

/* .title {
    font-size: 45px;
    font-weight: 800;
    margin: 50px auto;
    color: #3ab5fb;
} */

.login-section .logo {
    margin: 160px auto 0;
}
.otp-section .logo {
    margin: 100px auto 0;
}

.logo img {
    width:75px;
}

.input-section, .register-form, .addpatient-form, #edit-form, .support-form {
    font-family: 'Poppins', sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 auto;
    width: 98%;
}

#input-field {
    padding: 20px 25px;
    margin: 40px auto 20px;
    color: #535050;
    border-radius: 10px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1.5px;
    width: 40%;
}
.inputField {
    border: 2px solid #ededed;
}
::-webkit-input-placeholder {
    color: #a6a6a6;
    opacity: 1; 
}
:-ms-input-placeholder {
    color: #a6a6a6;
    opacity: 1; 
}
::placeholder {
    color: #a6a6a6;
    opacity: 1; 
}
  
  :-ms-input-placeholder { 
    color: #a6a6a6;
}
  
  ::-ms-input-placeholder {
    color: #a6a6a6;
}

.getotp, .verify, .register {
    width: 99%;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
}

.getotp-btn, .verify-btn, .register-btn, .addpatient-btn, .save-btn {
    cursor: pointer;
    font-size: 2rem;
    background-color: #3ab5fb;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 20px auto 10px;
    padding: 20px 24px;
    border-radius: 10px;
    border: none;
    width: 44%;
}
.save-btn, .addpatient-btn {
    margin: 30px auto 50px;
}
.verify-btn {
   margin: 30px auto;
   width: 35%;
}

.register-btn {
    margin: 30px auto;
}

.get-otp .phone {
    font-size: 1.8rem;
}

.go-back {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 35%;
    margin: 30px auto;
}
.otp-number {
    margin: 40px 10px 20px;
}
.input-number {
    height: 70px;
    width: 70px;
    padding: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    border: 3px solid #3ab5fb;
    margin: 0 1rem;
    border-radius: 5px;
    text-align: center;
}

.goback-link, .timer {
    font-size: 28px;
    font-weight: 500;
    color: #3ab5fb;
    text-decoration: underline;
    margin: 0;
    padding: 0;
}

.timer {
    color: #a6a6a6;
    text-decoration: none;
}
.closeBtnContainer{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: relative;
    top: 30px;
}
.close-btn {
    padding: 20px;
}
.invalid {
    border: 2px solid #ff7a7a;
    background-image: url(/static/media/icon-error.97d72fca.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
}    
.field-error {
    display: none;
    font-size: 0.85em;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    position: relative;
    margin: 0 50px 10px 0;
    color: #ff7a7a;
}

/* ------- Media queries ------- */

@media(max-width:1100px) {

    #input-field {
        width: 45%;
    }
    .go-back {
        width: 50%;
    }
    .getotp-btn, .verify-btn, .register-btn, .addpatient-btn, .save-btn {
        width: 50%;
    }
    .save-btn, .register-btn, .addpatient-btn {
        width: 55%;
        margin: 30px auto 50px;
    }
}

@media(max-width:768px) {

    .register .title, .edit-page .title, .support .title {
        font-size: 32px;
    }
    #input-field {
        width: 45%;
        padding: 15px 20px;
        font-size: 1rem;
    }
    .get-otp .phone {
        font-size: 1.2rem;
    }
    .input-number {
        height: 60px;
        width: 60px;
        padding: 5px;
    }   
   .title {
        font-size: 45px;
        font-weight: 800;
        margin: 50px auto;
        color: #3ab5fb;
    }
    .getotp-btn, .verify-btn, .register-btn, .addpatient-btn, .save-btn {
        font-size: 1rem;
        padding: 15px 20px;
        width: 52%;
    }
    .verify-btn {
        width: 42%;
    }
    .register-btn {
        width: 58%;
    }
    .save-btn,  .addpatient-btn {
        width: 55%;
    }
    
    .go-back {
        width: 42%;
    }
    .goback-link, .timer {
        font-size: 20px;
    }
    .register-form, .addpatient-form, .support-form {
        width: 95%;
    }
    .register .title, .edit-page .title, .support .title {
        font-size: 24px;
    }
}

@media(max-width:425px) {
    #input-field { 
        width: 75%;
    }
    .getotp-btn, .verify-btn, .register-btn, .save-btn {
        margin: 0 auto;
        width: 88%;
    }
    .save-btn {
        margin: 30px auto 40px;
    }
    .register-btn, .addpatient-btn {
        margin: 30px auto;
        width: 93%;
    }
    .verify-btn {
        margin: 20px auto;
        width: 70%;
    }
    .go-back {
        width: 68%;
    }
    .input-number {
        padding: 2px;
    }
}

@media(max-width:320px) {
    
    #input-field {
        width: 80%;
    }
    .getotp-btn, .verify-btn, .register-btn, .save-btn {
        width: 95%;
    }
    .save-btn, .verify-btn {
        width: 92%;
    }
    .go-back {
        width: 83%;
    }
}
.register-form label, .addpatient-form label, #edit-form label {
    font-size: 32px;
    font-weight: 500;
    color: #3ab5fb;
    margin: 10px auto;
    width: 44%;
    text-align: left;
}

.addpatient-form .inputField, .support-form textarea, #edit-form input, .register-form input, .register-form select, #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
    padding: 20px 25px;
    margin: 0 0 10px;
    color: #535050;
    border-radius: 10px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1.5px;
    width: 40%;
}
.inputField {
    border: 2px solid #ededed;
}

.inputFieldWrap span.note {
    color: blue;
    font-size: 1rem;
}

.register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
    width: 44%;
    background-color: #ffffff;
    color: #a6a6a6;
}
.add-patient .title, .edit-page .title, .register .title, .support .title {
    font-size: 38px;
    font-weight: 600;
    margin: 40px auto 20px;
    color: #3ab5fb;
}
.invalid {
    border: 2px solid #ff7a7a;
    background-image: url(/static/media/icon-error.97d72fca.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
}

.add-patient .closeBtnContainer, .edit-page .closeBtnContainer{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    cursor: pointer;
}

.card-container .program-card-wrap {
    margin: 20px;
    width: 350px;
}

.program-card-wrap .program-card {
    background-color: #cdecfd;
}

.program-card-wrap .program-card.selected {
    background-color: #ececec;
}

.program-card .button-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 10px;
}

.card-container .program-card {
    padding: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-radius: 10px;
}

.program-card h2.title {
    text-align: center;
    font-size: 1.5rem;
    margin: 15px;
    color: #333;
}

.program-card .customize-program-icon {
    text-align: right;
}

.checked-note {
    color: green;
    font-style: italic;
}

.edit-page h1.title {
    padding: 10px;
    font-size: 1rem;
}

.edit-page h1.title span {
    font-size: 1.5rem;
    font-weight: bold;
}

#edit-form div.input-field-wrap {
    display: -webkit-flex;
    display: flex;
    width: inherit;
}

#edit-form .input-field-wrap .input-checkbox {
    max-width: 40px;
    max-height: 30px;
    margin: 15px;
}

#edit-form .checkbox-text {
    margin: auto 0;
}

#edit-form .input-group {
    padding: 10px 20px;
}

.input-group hr {
    margin-top: 40px;
}

#edit-form .service-charge {
    font-style: italic;
    margin-bottom: 20px;
    background: yellow;
    text-align: center;
}
.info-text {
    height: 40px;
}

.cbtn {
    padding: 10px;
    cursor: pointer;
    color: black;
    border-radius: 5px;
    border-style: unset;
    font-size: 1rem;
}

.cbtn-danger {
    color: white;
    background-color: #ff5656;
}

.cbtn-danger:before {
    content: "⨂ ";
}

.cbtn-success {
    color: white;
    background-color: #28b328;
}

.cbtn-edit {
    color: white;
    background-color: #3ab5fb;
}

.cbtn-center {
    margin: auto;
}

.cbtn-medium {
    width: 120px;
}

.cbtn-small {
    width: 120px;
}

td.vertical-spacing {
    padding: 10px;
}

@media(max-width:1100px) {

    .register-form label, .addpatient-form label, #edit-form label, .inputFieldWrap {
        width: 55%;
    }   
    .addpatient-form .inputField, .support-form textarea, #edit-form input, .inputFieldWrap, .register-form input, .register-form select, #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
        width: 50%;
    } 
    .register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
        width: 55%;
    }    
    
}

@media(max-width:768px) {

    .register-form label, .addpatient-form label, #edit-form label, .inputFieldWrap {
        font-size: 20px;
        width: 55%;
    }
    
    .addpatient-form .inputField, .support-form textarea, #edit-form input, .inputFieldWrap .register-form input, .register-form select,  #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
        width: 50%;
        padding: 15px 20px;
        font-size: 18px;
    }
    
    .register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
        width: 56%;
        padding: 15px 20px;
    }
    .add-patient .title, .edit-page .title, .register .title, .support .title {
        font-size: 28px;
    }
}

@media(max-width:425px) {

    
    .add-patient .title, .edit-page .title, .register .title, .support .title {
        font-size: 24px;
    }
    .register-form label, .addpatient-form label, #edit-form label, .inputFieldWrap {
        font-size: 20px;
        width: 91%;
    }
    
    .addpatient-form .inputField, .support-form textarea, #edit-form input, .inputFieldWrap .register-form input, .register-form select,  #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
        font-size: 16px;
        width: 80%;
    }
    
    .register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
        width: 93%;
    }
}


.modal {
    display: -webkit-flex;
    display: flex;  
    -webkit-justify-content: center;  
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}
  
.modal-content {
    font-family: 'Poppins', sans-serif;
    background-color: #fefefe;
    margin: 80px auto 40px;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
  
.close {
    cursor: pointer;
    color: #3ab5fb;
    position: relative;
    font-size: 28px;
    top: 20px;
    left: 220px;
}

.main-content {
    font-size: 32px;
    margin: 70px auto 30px;
}

.button-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 70%;
}

.cancel-btn, .logout-btn, .cashout-modal-btn {
    cursor: pointer;
    font-size: 24px;
    padding: 20px 25px;
    margin: 10px;
    background-color: #3ab5fb;
    color: white;
    border-radius: 10px;
    border: 2px solid #3ab5fb;
}

.cancel-btn {
    background-color: white;
    color: #3ab5fb;
    border: 2px solid #3ab5fb;
}

.resend-link {
    background-color: white;
    color: #3ab5fb;
    padding: 12px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid #3ab5fb;
}

.resend-link:hover {
    background-color: #3ab5fb;
    color: white;
}

@media(max-width:1100px) {

    .modal-content {
        width: 50%;
    }
}

@media(max-width:768px) {

    .modal-content {
        width: 60%;
    }
    .main-content {
        font-size: 24px;
    }
    .cancel-btn, .logout-btn, .cashout-modal-btn {
        font-size: 22px;
        padding: 15px 20px;
    }
    .close {
        left: 180px;
    }
    .main-content {
        margin: 50px auto 30px;
    }
}

@media(max-width:425px) {

    .modal-content {
        width: 70%;
    }
    .main-content {
        font-size: 22px;
    }
    .cancel-btn, .logout-btn, .cashout-modal-btn {
        font-size: 20px;
        padding: 15px 20px;
    }
    .close {
        left: 120px;
    }
}

@media(max-width:320px) {

    .modal-content {
        width: 80%;
    }
}
.popup {
    display: -webkit-flex;
    display: flex;  
    -webkit-justify-content: center;  
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}
.popup-content {
    background-color: #3ab5fb;
    margin: 0 auto 0;
    padding: 10px;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 90%; 
}
.popup-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}
.popup-message {
    color: white;
    font-size: 18px;
    font-weight: 450;
    margin: 0 0 10px;
    padding: 0 5px;
}
.popup-message .share {
    position: relative;
    top: 18px;
}
.logo {
    margin: 0;
    padding: 0 5px;
}
.popup-close-icon {
    border: none;
    
}
    
@media(max-width:450px) {

    .popup-container {
        display: block;
    }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
