.modal {
    display: flex;  
    justify-content: center;
    align-items: center;
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}
  
.modal-content {
    font-family: 'Poppins', sans-serif;
    background-color: #fefefe;
    margin: 80px auto 40px;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
  
.close {
    cursor: pointer;
    color: #3ab5fb;
    position: relative;
    font-size: 28px;
    top: 20px;
    left: 220px;
}

.main-content {
    font-size: 32px;
    margin: 70px auto 30px;
}

.button-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.cancel-btn, .logout-btn, .cashout-modal-btn {
    cursor: pointer;
    font-size: 24px;
    padding: 20px 25px;
    margin: 10px;
    background-color: #3ab5fb;
    color: white;
    border-radius: 10px;
    border: 2px solid #3ab5fb;
}

.cancel-btn {
    background-color: white;
    color: #3ab5fb;
    border: 2px solid #3ab5fb;
}

.resend-link {
    background-color: white;
    color: #3ab5fb;
    padding: 12px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid #3ab5fb;
}

.resend-link:hover {
    background-color: #3ab5fb;
    color: white;
}

@media(max-width:1100px) {

    .modal-content {
        width: 50%;
    }
}

@media(max-width:768px) {

    .modal-content {
        width: 60%;
    }
    .main-content {
        font-size: 24px;
    }
    .cancel-btn, .logout-btn, .cashout-modal-btn {
        font-size: 22px;
        padding: 15px 20px;
    }
    .close {
        left: 180px;
    }
    .main-content {
        margin: 50px auto 30px;
    }
}

@media(max-width:425px) {

    .modal-content {
        width: 70%;
    }
    .main-content {
        font-size: 22px;
    }
    .cancel-btn, .logout-btn, .cashout-modal-btn {
        font-size: 20px;
        padding: 15px 20px;
    }
    .close {
        left: 120px;
    }
}

@media(max-width:320px) {

    .modal-content {
        width: 80%;
    }
}