.register-form label, .addpatient-form label, #edit-form label {
    font-size: 32px;
    font-weight: 500;
    color: #3ab5fb;
    margin: 10px auto;
    width: 44%;
    text-align: left;
}

.addpatient-form .inputField, .support-form textarea, #edit-form input, .register-form input, .register-form select, #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
    padding: 20px 25px;
    margin: 0 0 10px;
    color: #535050;
    border-radius: 10px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1.5px;
    width: 40%;
}
.inputField {
    border: 2px solid #ededed;
}

.inputFieldWrap span.note {
    color: blue;
    font-size: 1rem;
}

.register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
    width: 44%;
    background-color: #ffffff;
    color: #a6a6a6;
}
.add-patient .title, .edit-page .title, .register .title, .support .title {
    font-size: 38px;
    font-weight: 600;
    margin: 40px auto 20px;
    color: #3ab5fb;
}
.invalid {
    border: 2px solid #ff7a7a;
    background-image: url(./../images/icon-error.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
}

.add-patient .closeBtnContainer, .edit-page .closeBtnContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.card-container .program-card-wrap {
    margin: 20px;
    width: 350px;
}

.program-card-wrap .program-card {
    background-color: #cdecfd;
}

.program-card-wrap .program-card.selected {
    background-color: #ececec;
}

.program-card .button-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
}

.card-container .program-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.program-card h2.title {
    text-align: center;
    font-size: 1.5rem;
    margin: 15px;
    color: #333;
}

.program-card .customize-program-icon {
    text-align: right;
}

.checked-note {
    color: green;
    font-style: italic;
}

.edit-page h1.title {
    padding: 10px;
    font-size: 1rem;
}

.edit-page h1.title span {
    font-size: 1.5rem;
    font-weight: bold;
}

#edit-form div.input-field-wrap {
    display: flex;
    width: inherit;
}

#edit-form .input-field-wrap .input-checkbox {
    max-width: 40px;
    max-height: 30px;
    margin: 15px;
}

#edit-form .checkbox-text {
    margin: auto 0;
}

#edit-form .input-group {
    padding: 10px 20px;
}

.input-group hr {
    margin-top: 40px;
}

#edit-form .service-charge {
    font-style: italic;
    margin-bottom: 20px;
    background: yellow;
    text-align: center;
}
.info-text {
    height: 40px;
}

.cbtn {
    padding: 10px;
    cursor: pointer;
    color: black;
    border-radius: 5px;
    border-style: unset;
    font-size: 1rem;
}

.cbtn-danger {
    color: white;
    background-color: #ff5656;
}

.cbtn-danger:before {
    content: "⨂ ";
}

.cbtn-success {
    color: white;
    background-color: #28b328;
}

.cbtn-edit {
    color: white;
    background-color: #3ab5fb;
}

.cbtn-center {
    margin: auto;
}

.cbtn-medium {
    width: 120px;
}

.cbtn-small {
    width: 120px;
}

td.vertical-spacing {
    padding: 10px;
}

@media(max-width:1100px) {

    .register-form label, .addpatient-form label, #edit-form label, .inputFieldWrap {
        width: 55%;
    }   
    .addpatient-form .inputField, .support-form textarea, #edit-form input, .inputFieldWrap, .register-form input, .register-form select, #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
        width: 50%;
    } 
    .register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
        width: 55%;
    }    
    
}

@media(max-width:768px) {

    .register-form label, .addpatient-form label, #edit-form label, .inputFieldWrap {
        font-size: 20px;
        width: 55%;
    }
    
    .addpatient-form .inputField, .support-form textarea, #edit-form input, .inputFieldWrap .register-form input, .register-form select,  #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
        width: 50%;
        padding: 15px 20px;
        font-size: 18px;
    }
    
    .register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
        width: 56%;
        padding: 15px 20px;
    }
    .add-patient .title, .edit-page .title, .register .title, .support .title {
        font-size: 28px;
    }
}

@media(max-width:425px) {

    
    .add-patient .title, .edit-page .title, .register .title, .support .title {
        font-size: 24px;
    }
    .register-form label, .addpatient-form label, #edit-form label, .inputFieldWrap {
        font-size: 20px;
        width: 91%;
    }
    
    .addpatient-form .inputField, .support-form textarea, #edit-form input, .inputFieldWrap .register-form input, .register-form select,  #p-name, #p-phone, #p-email, #p-age, #program-dropdown, #clinic-name, #phn-number, #fname, #lname, #e-mail, #council-dropdown, #reg-num, #specialization-dropdown {
        font-size: 16px;
        width: 80%;
    }
    
    .register-form #council-dropdown, .register-form #specialization-dropdown, .addpatient-form #program-dropdown, #edit-form #specialization-dropdown {
        width: 93%;
    }
}

